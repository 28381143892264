<template>
    <div>
        <b-row>
            <b-col lg="12" class="pb-2">
                <b-button @click="onSubmit()" size="lg">登出</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    methods:{
        async onSubmit() {
            console.log('hi')
            await this.axios.post(this.GLOBAL.serverSrc + "logout.php", {
                username: sessionStorage.getItem('account'),
                token: sessionStorage.getItem('token')
            });
            sessionStorage.removeItem("account");
            sessionStorage.removeItem("token");

            await this.$router.push({name: 'Home'});
            location.reload();
        },
    },
}
</script>